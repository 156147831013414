
import { Component, Vue } from 'vue-property-decorator'
import { Page, Table } from '../../types/common'
import { PageType } from '../../types/menu'
import AppMenu from './AppMenu.vue'
import { KeepAlive } from '@/utils/decorators'

@Component({
  name: 'MenuSetting',
  components: { AppMenu }
})
@KeepAlive
export default class MenuSetting extends Vue {
  private searchInfo = {
    menuName: ''
  }

  private tableData: Table = {
    loading: false,
    tr: [
      {
        label: '菜单名称',
        prop: 'menuName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '菜单路由',
        prop: 'url',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '菜单icon',
        prop: 'iconPath',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '菜单序号',
        prop: 'orderNum',
        minWidth: '100'
      },
      {
        label: '是否叶子节点',
        prop: 'isLeaf',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '状态',
        prop: 'status',
        minWidth: '100',
        showOverflowTooltip: true
      }
    ],
    data: []
  }

  private total = 0
  private page = 1
  private size = 10

  created () {
    this.getData()
    this.$nextTick(() => {
      (this.$refs.appMenu as any).getData()
    })
  }

  getData () {
    this.tableData.loading = true
    this.$axios.get<Page<PageType>>(this.$apis.menu.menuPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    })
      .then((res) => {
        this.tableData.data = res.list || []
        this.total = res.total || 0
        this.tableData.loading = false
      })
      .catch((err) => {
        this.tableData.loading = false
        console.log(err)
      })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 新增
  onAdd () {
    this.$router.push({ name: 'menuAdd' })
  }

  // 编辑
  onUpdate (id: string) {
    this.$router.push({
      name: 'menuUpdate',
      params: {
        id: id
      }
    })
  }

  // 配置
  onConfig (row: {menuId: string;menuName: string}) {
    this.$router.push({
      name: 'menuConfig',
      query: { menuName: row.menuName },
      params: { id: row.menuId }
    })
  }

  onDisable (menuId: string) {
    this.$axios.post(this.$apis.menu.menuDisable, {
      menuId: menuId
    })
      .then(() => {
        this.$message({
          message: '操作成功',
          type: 'success'
        })
        this.getData()
      })
      .catch(err => {
        this.$message.error(err)
      })
  }
}
